import Report from '../models/Report';

import allCareApi from './allCareApi';

export default async function postRevision(report: Report) {
  await allCareApi.patch(
    `/tasks?requestId=${report.requestId}`,
    report,
  );
}
