import { styled } from 'solid-styled-components';

import { useNavigate } from '@solidjs/router';
import { leftTaskCount } from '../stores/tasksStore';

const Container = styled('nav')`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  padding: 1em;
  background: #d9d9d9;
  border: 1px solid;
  border-radius: 5px;

  button {
    width: 100%;
    font-size: 1.5em;
    padding-block: 0.5em;
    background: #fff;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export default function Navigation() {
  const navigate = useNavigate();

  return (
    <Container>
      <button onClick={() => navigate('/')}>
        남은 보고서: {leftTaskCount()}
      </button>
      <button onClick={() => navigate('/waiting')}>
        대기 목록
      </button>
      <button onClick={() => navigate('/complete')}>
        완료된 보고서
      </button>
    </Container>
  );
}
