import { createEffect } from 'solid-js';

import { Outlet, useNavigate } from '@solidjs/router';

import useAuthorize from '../hooks/useAuthorize';

import Loading from './Loading';
import Modal from './Modal';

export default function RouteGuard() {
  const authorize = useAuthorize();

  const navigate = useNavigate();

  createEffect(() => {
    authorize().catch(() => {
      navigate('/login');
    });
  });

  return (
    <>
      <Outlet />
      <Loading />
      <Modal />
    </>
  );
}
