import { Route, Router, Routes } from '@solidjs/router';

import RevisionTasksPage from './pages/RevisionTasksPage';
import WaitingTasksPage from './pages/WaitingTasksPage';
import CompleteTasksPage from './pages/CompleteTasksPage';

import LoginPage from './pages/LoginPage';
import OtpPage from './pages/OtpPage';

import RouteGuard from './components/RouteGuard';

function AuthorizedPages() {
  return (
    <Route path="/" component={RouteGuard}>
      <Route path="/" component={RevisionTasksPage} />
      <Route path="/waiting" component={WaitingTasksPage} />
      <Route path="/complete" component={CompleteTasksPage} />
    </Route>
  );
}

function PublicPages() {
  return (
    <>
      <Route path="/login" component={LoginPage} />
      <Route path="/otp" component={OtpPage} />
    </>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <AuthorizedPages />
        <PublicPages />
      </Routes>
    </Router>
  );
}
