import Guideline from './Guideline';

export default class Report {
  guidelines: Guideline[];

  keyword: string;

  keywordExplanation: string;

  report: string;

  reportType: string;

  requestId: string;

  saeteukExample: string;

  topic: string;

  topicExplanation: string;

  constructor({
    guidelines,
    keyword,
    keywordExplanation,
    report,
    reportType,
    requestId,
    saeteukExample,
    topic,
    topicExplanation,
  }: {
    guidelines: Guideline[];
    keyword: string;
    keywordExplanation: string;
    report: string;
    reportType: string;
    requestId: string;
    saeteukExample: string;
    topic: string;
    topicExplanation: string;
  }) {
    this.guidelines = guidelines;
    this.keyword = keyword;
    this.keywordExplanation = keywordExplanation;
    this.report = report;
    this.reportType = reportType;
    this.requestId = requestId;
    this.saeteukExample = saeteukExample;
    this.topic = topic;
    this.topicExplanation = topicExplanation;
  }

  get isReferenceFilled(): boolean {
    return this.guidelines.every((g) => (
      g.references.every((i) => (
        Boolean(i.title) && Boolean(i.url)
      ))
    ));
  }

  updateField(key: string, value: string): Report {
    return new Report({
      ...this,
      [key]: value,
    });
  }

  updateGuidelineTitle(index: number, value: string): Report {
    return new Report({
      ...this,
      guidelines: this.guidelines.map((g, i) => {
        if (i !== index) {
          return g;
        }
        return g.updateTitle(value);
      }),
    });
  }

  updateGuidelineExplanation(index: number, explanation: string): Report {
    return new Report({
      ...this,
      guidelines: this.guidelines.map((g, i) => {
        if (i !== index) {
          return g;
        }
        return g.updateExplanation(explanation);
      }),
    });
  }

  updateReferenceTitle({ guidelineIndex, referenceIndex, value }: {
    guidelineIndex: number;
    referenceIndex: number;
    value: string;
  }): Report {
    return new Report({
      ...this,
      guidelines: this.guidelines.map((g, i) => {
        if (i !== guidelineIndex) {
          return g;
        }
        return g.updateReferenceTitle(referenceIndex, value);
      }),
    });
  }

  updateReferenceUrl({ guidelineIndex, referenceIndex, value }: {
    guidelineIndex: number;
    referenceIndex:number;
    value: string;
  }): Report {
    return new Report({
      ...this,
      guidelines: this.guidelines.map((g, i) => {
        if (i !== guidelineIndex) {
          return g;
        }
        return g.updateReferenceUrl(referenceIndex, value);
      }),
    });
  }
}
