import postSession from '../services/postSession';

export default function useLogin() {
  const login = async ({ code, email }:{
    code: string; email: string;
  }) => {
    try {
      const accessToken = await postSession({ email, code });

      localStorage.setItem('ACCESS_TOKEN', accessToken);
    } catch (e) {
      throw new Error('인증 실패');
    }
  };

  return login;
}
