import CompleteTask from '../models/CompleteTask';

import allCareApi from './allCareApi';

export default async function fetchWaitingTasks(): Promise<CompleteTask[]> {
  const { data } = await allCareApi.get<any[]>('/tasks?status=DONE');

  const tasks = data.map((r) => ({
    requestId: r.requestId,
    type: r.type,
    major: r.major,
    reportType: r.reportType,
    level: r.level,
    activityType: r.activityType,
    subject: r.subject,
    subSubject: r.subSubject,
    activityKeywords: r.activityKeywords,
    keyword: r.keyword,
  }));

  return tasks;
}
