import { styled } from 'solid-styled-components';

import ChangeEventHandler from '../types/ChangeEvent';

const Title = styled('textarea')<{ background: string }>`
  font-size: 2em !important;
  display: block;
  width: calc(100% - 1.5em);
  background: ${(props) => props.background};
  margin-bottom: 0.25em;
  padding: 0.75em;
  font-weight: 600;
  border-radius: 10px;
`;

const Label = styled('label')`
  font-size: 1.5em;
  margin-bottom: 1em;
  display: block;
`;

const Textarea = styled('textarea')<{
  background: string;
  height: string;
}>`
  padding: 1em;
  background: ${(props) => props.background};
  height: ${(props) => props.height};
  width: calc(100% - 2em);
`;

export default function TextareaSection(props: {
  title: string;
  body: string;
  label: string;
  background: string;
  height?: string;
  isTitleChangable: boolean;
  onChange: (text: string) => void;
  onTitleChange?: (text: string) => void;
}) {
  const handleTitleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { value } = e.currentTarget;

    if (!props.onTitleChange) {
      return;
    }

    props.onTitleChange(value);
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { value } = e.currentTarget;
    props.onChange(value);
  };

  return (
    <section>
      <Label>{props.label}</Label>
      <Title
        background={props.background}
        value={props.title}
        onInput={handleTitleChange}
        disabled={!props.isTitleChangable}
       />
      <Textarea
        background={props.background}
        height={props.height || '35em'}
        value={props.body}
        onInput={handleChange}
      />
    </section>
  );
}
