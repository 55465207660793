import { Show } from 'solid-js';

import { styled } from 'solid-styled-components';

import Report from '../components/Report';

import { firstTask } from '../stores/tasksStore';

import PageLayout from '../components/PageLayout';

import Navigation from '../components/Navigation';

const Container = styled('div')`
  nav {
    margin-bottom: 2em;
  }

  textarea {
    font-size: 16px;
    padding-block: 1em;
    line-height: 1.5;
    font-weight: 500;
    color: #333;
    outline: none;
    border: none;
    border-radius: 10px;
  }
`;

export default function RevisionTasksPage() {
  return (
    <PageLayout>
      <Container>
        <Navigation />
        <Show keyed={false} when={firstTask()} fallback={(
          <div>
            검수할 보고서가 없습니다
          </div>
        )}>
          {(task) => (
            <div>
              <Report
                request={task().request}
                report={task().report}
              />
            </div>
          )}
        </Show>
      </Container>
    </PageLayout>
  );
}
