import authenticationApi from './authenticationApi';

export default async function postSession({ code, email }: {
  code: string;
  email: string;
}) {
  const { data } = await authenticationApi.post('/sessions', {
    code, email,
  });

  return data.accessToken;
}
