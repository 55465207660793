import { styled } from 'solid-styled-components';

import { createResource, For } from 'solid-js';

import PageLayout from '../components/PageLayout';
import Navigation from '../components/Navigation';

import fetchWaitingTasks from '../services/fetchWaitingTasks';

import WaitingTask from '../models/WaitingTask';

const Container = styled('div')`
  nav {
    margin-bottom: 2em;
  }
`;

const TaskContainer = styled('div')`
  margin-bottom: 1em;
  padding: 0.5em;
  border: 1px solid;
  border-radius: 5px;

  h3 {
    margin: 0;
  }
`;

function Task(props: { task: WaitingTask; }) {
  const title = () => ({
    창체: props.task.activityKeywords,
    세특: props.task.subSubject,
  }[props.task.reportType]);

  const requestId = () => props.task.requestId;
  const subject = () => props.task.subject;
  const major = () => props.task.major;
  const activityType = () => props.task.activityType;
  const level = () => props.task.level;
  const reportType = () => props.task.reportType;
  const type = () => props.task.type;

  return (
    <TaskContainer>
      <h3>{title()}, {reportType()}|{type()}</h3>
      <div>requestId: {requestId()}</div>
      <div>과목: {subject()}</div>
      <div>학과: {major()}</div>
      <div>활동유형: {activityType()}</div>
      <div>활동단계: {level()}</div>
    </TaskContainer>
  );
}

export default function WaitingTasksPage() {
  const [tasks] = createResource(fetchWaitingTasks);

  return (
    <PageLayout>
      <Container>
        <Navigation />
        <For each={tasks()} fallback={<div>제작중인 보고서가 없습니다</div>}>
          {(task) => (
            <Task task={task} />
          )}
        </For>
      </Container>
    </PageLayout>
  );
}
