import { createSignal } from 'solid-js';

const [email, setEmail] = createSignal('');

const [code, setCode] = createSignal('');

const userStore = {
  email,

  code,

  changeEmail(newEmail: string) {
    setEmail(newEmail);
  },

  changeCode(newCode: string) {
    setCode(newCode);
  },
};

export default userStore;
