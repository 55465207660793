import { styled } from 'solid-styled-components';

import Report from '../models/Report';

import GuidelineItem from './GuidelineItem';

const Container = styled('div')`
  div {
    margin-bottom: 1em;
  }
`;

const Title = styled('h3')`
  font-size: 1.25em;
  padding: 0.8em;
  background: #F0F1FF;
  border-radius: 10px;
`;

export default function GuidelineSection(props: {
  report: Report
}) {
  return (
    <Container>
      <Title>✅ 탐구활동 가이드라인</Title>
      <GuidelineItem
        index={0}
        label="🔥 STEP 1."
        background="#E5FDFF"
        guideline={props.report.guidelines[0]}
      />
      <GuidelineItem
        index={1}
        label="🔍 STEP 2."
        background="#FFFDE7"
        guideline={props.report.guidelines[1]}
      />
      <GuidelineItem
        index={2}
        label="🧪 STEP 3."
        background="#FFE5E5"
        guideline={props.report.guidelines[2]}
      />
      <GuidelineItem
        index={3}
        label="💫️ STEP 4."
        background="#E9FFE5"
        guideline={props.report.guidelines[3]}
      />
      <GuidelineItem
        index={4}
        label="🎉 STEP 5."
        background="#F0E8FA"
        guideline={props.report.guidelines[4]}
      />
    </Container>
  );
}
