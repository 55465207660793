import { styled } from 'solid-styled-components';

import Report from '../models/Report';

import useUpdateReport from '../hooks/useUpdateReport';
import useReorder from '../hooks/useReorder';
import useSubmitReport from '../hooks/useSubmitReport';

import TextareaSection from './TextareaSection';
import GuidelineSection from './GuidelineSection';

import modalStore from '../stores/modalStore';

const Container = styled('section')`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonWrapper = styled('div')`
  padding-block: 1em;
  display: flex;
  justify-content: center;
  gap: 1em;

  button {
    font-size: 1.5em;
    font-weight: 600;
    padding: 1em 1em;
    background: #4B52FF;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
`;

export default function ReportSection(props: {
  report: Report
}) {
  const {
    updateKeyword,
    updateKeywordExplanation,
    updateTopic,
    updateTopicExplanation,
    updateSaeteukExample,
    updateAbstract,
  } = useUpdateReport();

  const reorder = useReorder();

  const submitRevision = useSubmitReport();

  const handleClickReorder = async () => {
    modalStore.openModal({
      text: '보고서를 재요청할까요?',
      onConfirm: async () => {
        await reorder(props.report.requestId);
        modalStore.closeModal();
      },
    });
  };

  const handleClickSubmit = async () => {
    if (!props.report.isReferenceFilled) {
      alert('가이드라인 참고자료가 비어있어요.');
      return;
    }

    modalStore.openModal({
      text: '보고서를 제출 할까요?',
      onConfirm: async () => {
        modalStore.closeModal();
        await submitRevision(props.report);
      },
    });
  };

  return (
    <Container>
      <TextareaSection
        title={props.report.keyword}
        body={props.report.keywordExplanation}
        label="🧐 탐구 키워드"
        background="#FFF2F2"
        onTitleChange={updateKeyword}
        onChange={updateKeywordExplanation}
        isTitleChangable
      />
      <ButtonWrapper>
        <button onClick={handleClickReorder}>
          재요청하기
        </button>
      </ButtonWrapper>
      <TextareaSection
        title={props.report.topic}
        body={props.report.topicExplanation}
        label="🧐 탐구 주제"
        background="#DBEFFA"
        onTitleChange={updateTopic}
        onChange={updateTopicExplanation}
        isTitleChangable
      />
      <GuidelineSection report={props.report} />
      <TextareaSection
        title="생기부 내용 예시 - 이 활동을 하면 이런 생기부가 나옵니다"
        body={props.report.saeteukExample}
        label="🎓 생기부 내용 예시"
        background="#FFF2E5"
        height="10em"
        onChange={updateSaeteukExample}
        isTitleChangable={false}
      />
      <TextareaSection
        title="보고서 서론 예시 - 참고해서 작성하세요"
        body={props.report.report}
        label="🎒 보고서 서론 예시"
        background="#F0E8FA"
        height="40em"
        onChange={updateAbstract}
        isTitleChangable={false}
      />
      <ButtonWrapper>
        <button onClick={handleClickReorder}>
          재요청하기
        </button>
        <button onClick={handleClickSubmit}>
          제출 하기
        </button>
      </ButtonWrapper>
    </Container>
  );
}
