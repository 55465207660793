import { updateReport } from '../stores/tasksStore';

export default function useUpdateReport() {
  const updateKeyword = (value: string) => {
    updateReport((report) => report.updateField('keyword', value));
  };

  const updateKeywordExplanation = (value: string) => {
    updateReport((report) => report.updateField('keywordExplanation', value));
  };

  const updateTopic = (value: string) => {
    updateReport((report) => report.updateField('topic', value));
  };

  const updateTopicExplanation = (value: string) => {
    updateReport((report) => report.updateField('topicExplanation', value));
  };

  const updateSaeteukExample = (value: string) => {
    updateReport((report) => report.updateField('saeteukExample', value));
  };

  const updateAbstract = (value: string) => {
    updateReport((report) => report.updateField('report', value));
  };

  const updateGuidelineTitle = ({ index, title }: {
    index: number; title: string;
  }) => {
    updateReport((report) => report.updateGuidelineTitle(index, title));
  };

  const updateGuidelineExplanation = ({ index, explanation }: {
    index: number; explanation: string;
  }) => {
    updateReport((report) => report.updateGuidelineExplanation(index, explanation));
  };

  const updateReferenceTitle = ({
    guidelineIndex,
    referenceIndex,
    value,
  }: {
    guidelineIndex: number;
    referenceIndex: number;
    value: string;
  }) => {
    updateReport((report) => report.updateReferenceTitle({
      guidelineIndex,
      referenceIndex,
      value,
    }));
  };

  const updateReferenceUrl = ({
    guidelineIndex,
    referenceIndex,
    value,
  }: {
    guidelineIndex: number;
    referenceIndex: number;
    value: string;
  }) => {
    updateReport((report) => report.updateReferenceUrl({
      guidelineIndex,
      referenceIndex,
      value,
    }));
  };

  return {
    updateKeyword,
    updateKeywordExplanation,
    updateTopic,
    updateTopicExplanation,
    updateSaeteukExample,
    updateAbstract,
    updateGuidelineTitle,
    updateGuidelineExplanation,
    updateReferenceTitle,
    updateReferenceUrl,
  };
}
