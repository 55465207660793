import Report from '../models/Report';

import postRevision from '../services/postRevision';

import loadingStore from '../stores/loadingStore';
import { refreshTasks } from '../stores/tasksStore';

export default function useSubmitRevision() {
  const submitRevision = async (report: Report) => {
    loadingStore.startLoading();

    await postRevision(report);
    refreshTasks();

    window.scrollTo(0, 0);

    loadingStore.endLoading();
  };

  return submitRevision;
}
