import postReorder from '../services/postReorder';

import loadingStore from '../stores/loadingStore';
import { refreshTasks } from '../stores/tasksStore';

export default function useReorder() {
  const reorder = async (requestId: string) => {
    loadingStore.startLoading();

    await postReorder(requestId);
    refreshTasks();

    window.scrollTo(0, 0);

    loadingStore.endLoading();
  };

  return reorder;
}
