import { zip } from 'lodash';

import Request from '../models/Request';
import Report from '../models/Report';
import Guideline from '../models/Guideline';

import allCareApi from './allCareApi';

function parseGuidelines(guidelines: string, guidelineExplanations: string) {
  return zip(
    guidelines.split(', '),
    guidelineExplanations.split('\n\n'),
  )
    .map(([title, explanation]) => (
      new Guideline({ title, explanation })
    ));
}

type TaskResponse = {
  request: Request;
  report: {
    guidelineExplanations: string;
    guidelineKeywords: string;
    guidelines: string;
    keyword: string;
    keywordExplanation: string;
    report: string;
    reportType: string;
    requestId: string;
    saeteukExample: string;
    topic: string;
    topicExplanation: string;
  };
}[];

export default async function fetchReports() {
  const { data } = await allCareApi.get<TaskResponse>('/tasks?status=REVISION');

  return data.map(({ request, report }) => ({
    request,
    report: new Report({
      guidelines: parseGuidelines(
        report.guidelines,
        report.guidelineExplanations,
      ),
      keyword: report.keyword,
      keywordExplanation: report.keywordExplanation,
      report: report.report,
      reportType: report.reportType,
      requestId: report.requestId,
      saeteukExample: report.saeteukExample,
      topic: report.topic,
      topicExplanation: report.topicExplanation,
    }),
  }));
}
