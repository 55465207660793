import Reference from './Reference';

export default class Guideline {
  title: string;

  explanation: string;

  references: [Reference, Reference];

  constructor({
    title = '',
    explanation = '',
    references = [
      new Reference(),
      new Reference(),
    ],
  }: {
    title?: string;
    explanation?: string;
    references?: [Reference, Reference];
  }) {
    this.title = title;
    this.explanation = explanation;
    this.references = references;
  }

  updateTitle(value: string): Guideline {
    return new Guideline({
      ...this,
      title: value,
    });
  }

  updateExplanation(value: string): Guideline {
    return new Guideline({
      ...this,
      explanation: value,
    });
  }

  updateReferenceTitle(referenceIndex: number, value: string): Guideline {
    return new Guideline({
      ...this,
      references: this.references.map((r, i) => {
        if (i !== referenceIndex) {
          return r;
        }
        return r.updateTitle(value);
      }),
    });
  }

  updateReferenceUrl(referenceIndex: number, value: string): any {
    return new Guideline({
      ...this,
      references: this.references.map((r, i) => {
        if (i !== referenceIndex) {
          return r;
        }
        return r.updateUrl(value);
      }),
    });
  }
}
