import { createResource } from 'solid-js';

import fetchTasks from '../services/fetchTasks';

import Report from '../models/Report';

const [tasks, { mutate, refetch }] = createResource(fetchTasks, {
  initialValue: [],
});

export const leftTaskCount = () => tasks().length;

export const firstTask = () => tasks()[0];

export const updateReport = (
  updater: (report: Report) => Report,
) => {
  const task = firstTask();

  mutate((prev) => [
    {
      ...task,
      report: updater(task.report),
    },
    ...prev.slice(1),
  ]);
};

export const refreshTasks = () => {
  refetch();
};
