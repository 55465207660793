import ReportSection from './ReportSection';
import RequestSection from './RequestSection';

import ReportModel from '../models/Report';
import Request from '../models/Request';

export default function Report(props: {
  request: Request, report: ReportModel
}) {
  return (
    <div>
      <RequestSection
        title={props.report.keyword}
        request={props.request}
      />
      <ReportSection report={props.report} />
    </div>
  );
}
