import { styled } from 'solid-styled-components';

const Container = styled('div')`
  max-width: 1024px;
  margin: 3em auto;
  padding-inline: 1em;

  line-height: 1.5;
  font-weight: 500;
  color: #333;
`;

export default function PageLayout(props: {
  children: any;
}) {
  return (
    <Container>
      {props.children}
    </Container>
  );
}
