const env = {
  ALLCARE_API: process.env.ALLCARE_API!,
  AUTHENTICATE_API: process.env.THANOS_API!,
  SAENGGIBU_APP: process.env.SAENGGIBU_APP!,
};

Object.entries(env)
  .forEach(([key, value]) => {
    if (!value) {
      throw Error(`Environment variable not set up. '${key}'`);
    }
  });

export default env;
