import { Show } from 'solid-js';

import { Puff } from 'solid-spinner';

import { styled } from 'solid-styled-components';

import loadingStore from '../stores/loadingStore';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 1.5em;
  }
`;

export default function Loading() {
  return (
    <Show when={loadingStore.loading()}>
      <Overlay>
        <p>처리 중 입니다</p>
        <Puff />
      </Overlay>
    </Show>
  );
}
