import authenticationApi from './authenticationApi';

export default async function sessionExists(accessToken: string) {
  const { data } = await authenticationApi.get('/session', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
}
