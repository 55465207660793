import { styled } from 'solid-styled-components';

import Request from '../models/Request';

const Container = styled('div')`
  padding: 0.3em 1em;
  background: #EDEEFF;
  border-radius: 10px;
  
  label {
    font-weight: 700;
  }
`;

export default function RequestSection(props: {
  title: string;
  request: Request;
}) {
  return (
    <Container>
      <h2>{props.title}</h2>
      <p><label>RequestId</label> | {props.request.requestId}</p>
      <p><label>보고서 유형</label> | {props.request.reportType}</p>
      <p><label>희망 키워드</label> | {props.request.activityKeywords}</p>
      <p><label>목표 학과</label> | {props.request.major}</p>
      <p><label>활동 단계</label> | {props.request.level}</p>
      <p><label>탐구 유형</label> | {props.request.type}</p>
      <p><label>과목</label> | {props.request.subject}</p>
      <p><label>단원</label> | {props.request.subSubject}</p>
      <p><label>교과 내용</label> | {props.request.learningElements}</p>
      <p><label>제외 키워드</label> | {props.request.excludingKeywords}</p>
    </Container>
  );
}
