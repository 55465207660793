import sessionExists from '../services/sessionExists';

export default function useAuthorize() {
  const authorize = async () => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    if (!accessToken) {
      throw Error('인증 오류');
    }

    try {
      await sessionExists(accessToken);
    } catch (e) {
      throw Error('인증 오류');
    }
  };

  return authorize;
}
