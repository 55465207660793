import { createSignal } from 'solid-js';

const [isOpen, setIsOpen] = createSignal(false);

const [content, setContent] = createSignal('');

const [confirmCallback, setConfirmCallback] = createSignal<() => void>();

const modalStore = {
  isOpen,

  content,

  confirmCallback,

  openModal({
    text, onConfirm,
  }: {
    text: string;
    onConfirm: () => void;
  }) {
    setIsOpen(true);
    setContent(text);
    setConfirmCallback(() => onConfirm);
  },

  closeModal() {
    setIsOpen(false);
    setConfirmCallback();
  },
};

export default modalStore;
