import { styled } from 'solid-styled-components';

import { useNavigate } from '@solidjs/router';

import PageLayout from '../components/PageLayout';

import userStore from '../stores/userStore';

import usePublishAccessCode from '../hooks/usePublishAccessCode';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  h1 {
    margin: 0;
  }

  img {
    width: 50%;
  }
`;

const LogInForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    background-color: #4b52ff;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

const Field = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

export default function LoginPage() {
  const navigate = useNavigate();

  const publishAccessCode = usePublishAccessCode();

  const handleClick = async () => {
    try {
      await publishAccessCode(userStore.email());
      navigate('/otp');
    } catch (e) {
      alert('등록되지 않은 이메일입니다');
    }
  };

  const handleInput = (e: any) => {
    const { value } = e.currentTarget;
    userStore.changeEmail(value);
  };

  return (
    <PageLayout>
      <Container>
        <h1>올케어 어드민</h1>
        <img src="https://github.com/gringrape/coding-life/assets/53764714/b62df6dd-2ba3-4196-a87f-6c1400b3c2bc" />
        <LogInForm>
          <Field>
            <input
              placeholder='이메일을 입력해주세요'
              type="text"
              onInput={handleInput}
            />
          </Field>
          <button type="button" onClick={handleClick}>
            로그인
          </button>
        </LogInForm>
      </Container>
    </PageLayout>
  );
}
