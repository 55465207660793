import { Index } from 'solid-js';

import { styled } from 'solid-styled-components';

import useUpdateReport from '../hooks/useUpdateReport';

import Guideline from '../models/Guideline';

const Container = styled('div')<{ background: string }>`
  padding: 1em;
  background: ${(props) => props.background};
  border-radius: 10px;
`;

const Label = styled('label')`
  font-size: 1.25em;
`;

const Title = styled('input')<{ background: string }>`
  display: block;
  width: 100%;
  margin-block: 1em 0.5em; 
  font-size: 1.5em;
  background: ${(props) => props.background};
  border: none;
  outline: none;
`;

const Content = styled('textarea')<{ background: string }>`
  width: 100%;
  height: 5em;
  background: ${(props) => props.background};
`;

const Reference = styled('div')`
  margin-top: 1em;

  h5 {
    font-size: 1.25em;
    margin-bottom: 1em;
  }
`;

const Field = styled('div')`
  margin-bottom: 0.5em;
  display: flex;
  gap: 1em;

  input {
    font-size: 1em;
    display: block;
    padding: 4px 16px;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: none;
  }
`;

export default function GuidelineSection(props: {
  index: number;
  guideline: Guideline;
  label: string;
  background: string;
}) {
  const {
    updateGuidelineTitle,
    updateGuidelineExplanation,
    updateReferenceTitle,
    updateReferenceUrl,
  } = useUpdateReport();

  return (
    <Container background={props.background}>
      <Label>{props.label}</Label>
      <Title
        value={props.guideline.title}
        background={props.background}
        onInput={(e) => updateGuidelineTitle({
          index: props.index,
          title: e.currentTarget.value,
        })}
      />
      <Content
        value={props.guideline.explanation}
        background={props.background}
        onInput={(e) => updateGuidelineExplanation({
          index: props.index,
          explanation: e.currentTarget.value,
        })}
      />
      <Reference>
        <h5>참고 자료</h5>
        <Index each={props.guideline.references}>
          {(reference, referenceIndex) => (
            <Field>
              <input
                type='text'
                placeholder='제목'
                value={reference().title}
                onInput={(e) => updateReferenceTitle({
                  guidelineIndex: props.index,
                  referenceIndex,
                  value: e.currentTarget.value,
                })}
              />
              <input
                type='text'
                placeholder='url'
                value={reference().url}
                onInput={(e) => updateReferenceUrl({
                  guidelineIndex: props.index,
                  referenceIndex,
                  value: e.currentTarget.value,
                })}
              />
            </Field>
          )}
        </Index>
      </Reference>
    </Container>
  );
}
