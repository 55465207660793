import { Show } from 'solid-js';

import { styled } from 'solid-styled-components';

import modalStore from '../stores/modalStore';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled('div')`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 10px;
  gap: 1.5em;
  align-items: center;
  background: #fff;
  z-index: 2;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 0.5em;

  button {
    font-size: 0.8em;
    border: none;
    outline: none;
    padding: 0.5em 1em;
    border-radius: 10px;
    cursor: pointer;
  }
`;

const ConfirmButton = styled('button')`
  background: #4B52FF;
  color: #fff;
`;

const CancelButton = styled('button')`
  background: #EEEEF3;
  color: #666666;
`;

export default function Modal() {
  return (
    <Show when={modalStore.isOpen()}>
      <Overlay>
        <Container>
          {modalStore.content()}
          <ButtonWrapper>
            <ConfirmButton onClick={() => {
              const callback = modalStore.confirmCallback();

              if (callback) {
                callback();
              }
            }}>
              확인
            </ConfirmButton>
            <CancelButton onClick={() => modalStore.closeModal()}>
              취소
            </CancelButton>
          </ButtonWrapper>
        </Container>
      </Overlay>
    </Show>
  );
}
