export default class Reference {
  title: string;

  url: string;

  constructor({
    title = '',
    url = '',
  }: {
    title?: string;
    url?: string;
  } = {}) {
    this.title = title;
    this.url = url;
  }

  updateTitle(value: string): any {
    return new Reference({
      ...this,
      title: value,
    });
  }

  updateUrl(value: string): any {
    return new Reference({
      ...this,
      url: value,
    });
  }
}
