import { createSignal } from 'solid-js';

const [loading, setLoading] = createSignal(false);

const loadingStore = {
  loading,

  startLoading() {
    setLoading(true);
  },

  endLoading() {
    setLoading(false);
  },
};

export default loadingStore;
